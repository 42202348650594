import React from "react"
import Page from "../components/page.js"

export default () => {
  return (
    <Page>
      
      <div>
        'Emerging technologies in enforced disappearances investigations', 
        presentation at United Nations Human Rights Council event (September 2023):{" "}
        <a
          href="https://www.ohchr.org/en/press-releases/2023/09/un-experts-study-new-technologies-and-enforced-disappearances-exposes-risks"
          target="_blank"
          rel="noreferrer"
        >
          information
        </a>
      </div>

      <div>
        'Eavesdropping in conflict zones: On the comparative counter forensic value of light and sound', 
        presentation with Candice Strongwater at the Weaving Worlds conference
        at Delft University of Technology's Department of Architecture (June 2023):{" "}
        <a
          href="https://www.weaving.world/"
          target="_blank"
          rel="noreferrer"
        >
          information
        </a>
      </div>

      <div>
        'Eavesdropping: on the comparative counter forensic value of light and sound', 
        presentation at the Media Forensics symposium
        at Dartmouth College (March 2023):{" "}
        <a
          href="http://mediaforensics.host.dartmouth.edu/"
          target="_blank"
          rel="noreferrer"
        >
          information
        </a>
      </div>

      <div>
        'Mapping for Social Justice', 
        presentation at the 7th Annual Social Justice Teach-In
        at California Polytechnic State University (February 2023)
      </div>
    
      <div>
        'Open source information: Strengthening accountability at the
        intersection of law, technology, and the humanitarian space',
        presentation at an interdisciplinary expert's consultation
        at Geneva Academy (December 2022):{" "}
        <a
          href="https://www.geneva-academy.ch/news/detail/593-experts-discuss-the-use-of-open-source-information-to-document-ihl-and-human-rights-violations"
          target="_blank"
          rel="noreferrer"
        >
          information
        </a>
      </div>

      <div>
        Presentation during Jenna Krajeski's undergraduate capstone class at Yale University (October 2022)
      </div>

      <div>
        'Multidisciplinary Spatial Workflows', co-led lecture at Rutgers
        University’s Department of Landscape Architecture (April 2021):{" "}
        <a
          href="https://situ.nyc/fabrication/news/rutgers-landscape-architecture-multidisciplinary-spatial-workflows"
          target="_blank"
          rel="noreferrer"
        >
          information
        </a>
      </div>

      <div>
        On SITU's multidisciplinary practice, co-led lecture at University of
        Pennsylvania's Weitzman School of Design (March 2021):{" "}
        <a
          href="https://situ.nyc/fabrication/news/situ-shares-multidisciplinary-practice-with-landscape-architecture-students-at-upenn"
          target="_blank"
          rel="noreferrer"
        >
          information
        </a>
      </div>

      <div>
        'Beyond the Frame', lecture at UCLA Promise Institute (August 2020):{" "}
        <a
          href="https://www.youtube.com/watch?v=MWXeFv0z5G0"
          target="_blank"
          rel="noreferrer"
        >
          video
        </a>{" "}
        +{" "}
        <a
          href="https://www.promisehumanrights.blog/blog/2020/9/open-source-information-as-digital-evidence-panel-discusses-the-role-of-technology-in-modern-human-rights"
          target="_blank"
          rel="noreferrer"
        >
          information
        </a>
      </div>

      <div>
        'Counter-Narrative / Counter-Surveillance', lecture at Chen's (July
        2020):{" "}
        <a href="https://vimeo.com/436819621" target="_blank" rel="noreferrer">
          video
        </a>
      </div>

      <div>
        Workshop session at Creative Time Summit (November 2019):{" "}
        <a
          href="https://creativetime.org/summit/summit-x-breakout-session-counter-surveillance-and-truth-mapping-workshop/"
          target="_blank"
          rel="noreferrer"
        >
          information
        </a>
      </div>

      <div>
        Presentation at a WITNESS symposium on Digital Forensics (January 2019)
      </div>
    </Page>
  )
}
